/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Banner from './components/Banner';

const bannerContent = [
  {
    bannerID: 'bannerDS',
    displayAcknowledgeBannerButton: true,
    notificationStartTime: '2021-05-06T00:00:00+01:00',
    notificationEndTime: '',
    bannerContents: () => (
      <>
        <strong>
          PLEASE NOTE:
        </strong>
        {' '}
        Banners can be used to display messages to users and update them with important news
        and events.
      </>
    ),
  },
];

const bannerContentsArray = bannerContent.map((x) => <Banner {...x} />);

export default bannerContentsArray;
