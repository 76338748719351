/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import {
  Container,
  Segment,
  Breadcrumb,
  Card,
  Form,
  Button,
  Message,
  Icon,
  Ref,
} from 'semantic-ui-react';

import { LINK_ARIDHIA_CONTACT_SD } from 'env-create-react-app';

const RequestWorkspace = () => {
  const valuesOrig = {
    vm_linux: false,
    vm_windows: false,
  };

  const [values, setValues] = useState(valuesOrig);

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const formRef = useRef(null);

  const [message, setMessage] = useState({
    showMessage: false,
    header: '',
    message: '',
    isLoading: '',
    isError: false,
    isSuccess: false,
  });

  const onChange = (event, result) => {
    const { name, value } = result || event.target;
    setValues({ ...values, [name]: value });
  };

  const onChangeCheckbox = (event, result) => {
    const { name, checked } = result || event.target;
    setValues({ ...values, [name]: checked });
  };

  const hideMessage = () => {
    setMessage({ showMessage: false });
    setValues(valuesOrig);
    formRef.current.reset();
    setSubmitDisabled(false);
  };

  const handleSuccessMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Message Sent',
      message: (
        <>
          This form is for demo purposes only and a Workspace request has
          {' '}
          <strong>not</strong>
          {' '}
          been generated. If you would like to request a workspace please
          contact the
          {' '}
          <a href={LINK_ARIDHIA_CONTACT_SD} target="_blank" rel="noreferrer">
            Aridhia Service Desk
          </a>
          .
        </>
      ),
      isLoading: false,
      isError: false,
      isSuccess: true,
    });
  };

  const displayMessageContent = () => {
    if (message.isLoading) {
      return (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>{message.header}</Message.Header>
            <p>{message.message}</p>
          </Message.Content>
        </Message>
      );
    }

    if (message.isError) {
      return (
        <Message negative onDismiss={hideMessage}>
          <Message.Header>{message.header}</Message.Header>
          <p>{message.message}</p>
          <p>
            If you keep seeing this message, please
            {' '}
            <a href={LINK_ARIDHIA_CONTACT_SD} target="_blank" rel="noreferrer">
              contact the Service Desk
            </a>
            .
          </p>
        </Message>
      );
    }

    if (message.isSuccess) {
      return (
        <Message positive onDismiss={hideMessage}>
          <Message.Header>{message.header}</Message.Header>
          <p>{message.message}</p>
        </Message>
      );
    }

    return (
      <Message onDismiss={hideMessage}>
        <Message.Header>{message.header}</Message.Header>
        <p>{message.message}</p>
      </Message>
    );
  };

  const onSubmit = () => {
    setSubmitDisabled(true);

    setMessage({
      showMessage: true,
      header: 'Just one second',
      message: 'We are submitting your form.',
      isLoading: true,
      isError: false,
      isSuccess: false,
    });

    setTimeout(() => {
      handleSuccessMessage();
    }, 2000);
  };

  return (
    <div className="portal page extra content">
      <Container>
        <Segment className="portal-elements">
          <Breadcrumb>
            <Breadcrumb.Section href="/">Home</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Request a Workspace</Breadcrumb.Section>
          </Breadcrumb>
        </Segment>
        <Card fluid>
          <Card.Header className="content">
            <Icon name="exclamation circle" size="large" className="right floated blue" />
            <span className="workspace-name">Request a Workspace - DEMO</span>
          </Card.Header>
          <Card.Content>
            <Card.Description>
              <p>
                Forms can be used for automating tasks such as requesting a new Workspace for your
                hub.
              </p>
              <p>
                <strong>
                  PLEASE NOTE: This form is for demo purposes and will not generate a Workspace
                  request.
                  {' '}
                </strong>
              </p>
              <p>
                <strong>
                  If you would like to request a workspace please
                  {' '}
                  <a href={LINK_ARIDHIA_CONTACT_SD} target="_blank" rel="noreferrer">
                    contact the Service Desk
                  </a>
                  .
                </strong>
              </p>
            </Card.Description>
          </Card.Content>
          <Card.Content>
            <Ref innerRef={formRef}>
              <Form id="form_request_workspace" onSubmit={onSubmit}>
                <Form.Field required>
                  <Form.Input
                    label="Project Name"
                    name="project_name"
                    placeholder="Project Name"
                    onChange={onChange}
                    required
                  />
                </Form.Field>
                <Form.Field required>
                  <Form.TextArea
                    label="Project Description"
                    name="project_description"
                    placeholder="Please describe your project"
                    onChange={onChange}
                    rows="3"
                    required
                  />
                </Form.Field>
                <Form.Field>
                  <Form.TextArea
                    label="Additional Users"
                    name="additional_users"
                    placeholder="Please provide email addresses of other users who should be invited to the workspace"
                    onChange={onChange}
                    rows="3"
                  />
                </Form.Field>
                <Form.Field required>
                  <label required>What hub do you need your workspace to be located?</label>
                  <select
                    label="What hub do you need your workspace to be located?"
                    name="workspace_hub"
                    required
                    onChange={onChange}
                  >
                    <option value="">Select...</option>
                    <option value="uswest2">US West 2</option>
                    <option value="uksouth">UK South</option>
                    <option value="westeurope">West Europe</option>
                  </select>
                </Form.Field>
                <Form.Group className="grouped">
                  <h5>Do you require a virtual machine?</h5>
                  <Form.Field>
                    <Form.Checkbox
                      id="vm_windows"
                      name="vm_windows"
                      label="Windows Virtual Machine"
                      onClick={onChangeCheckbox}
                      checked={values.vm_windows}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Checkbox
                      id="vm_linux"
                      name="vm_linux"
                      label="Linux Virtual Machine"
                      onClick={onChangeCheckbox}
                      checked={values.vm_linux}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Field>
                  <Form.TextArea
                    label="Any other requirements?"
                    name="other_requirements"
                    placeholder="Please provide any additional details to help us set up your workspace"
                    onChange={onChange}
                    rows="3"
                  />
                </Form.Field>
                <Form.Field>{message.showMessage ? displayMessageContent() : ''}</Form.Field>
                <div>
                  <Button
                    id="form_request_workspace_submit"
                    type="submit"
                    disabled={submitDisabled}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Ref>
          </Card.Content>
        </Card>
      </Container>
    </div>
  );
};

export default RequestWorkspace;
