/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Container, Segment, Grid, Header, Card, Icon, Divider,
} from 'semantic-ui-react';
import {
  LINK_PREVIEW_FAIR,
  LINK_PREVIEW_FAIR_DATASETS,
  LINK_ARIDHIA_CONTACT_SD,
  LINK_ARIDHIA_SHINY_APPS,
  LINK_ARIDHIA_LEARNING_CENTRE,
  LINK_EMBED_PREVIEW_OVERVIEW_VIDEO,
  LINK_ARIDHIA_KB,
  ENDPOINT_SECURITY,
  ENDPOINT_REQUEST_WORKSPACE,
} from 'env-create-react-app';

import FAIRLogo2020White from '../images/FAIRLogo2020-white.svg';
import FAIRLogo2020Green from '../images/fair-logo-green.svg';
import workspacesLogo2020Coloured from '../images/workspaces-logo-colour.svg';
import poweredByAridhia from '../images/AridhiaDRE-PoweredBy.svg';

const Content = () => (
  <div className="portal page content">
    <Container>
      <Segment className="portal-elements">
        <Header as="h2">Getting Started</Header>

        <Grid stackable columns="equal">
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <Icon name="cog" size="large" className="right floated blue" />
                <span className="workspace-name">This can be customised</span>
              </Card.Header>
              <Card.Content className="hero-content">
                <Card.Description className="hero-child">
                  <p>
                    <strong>
                      This can be customised to your message, your branding and imagery. Different
                      pages can be displayed for uses depending on whether they're logged in or not.
                    </strong>
                  </p>
                  <p>
                    Nam congue laoreet lectus, id mollis tortor dignissim sed. Ut maximus lectus
                    eget mollis maximus. Vivamus metus eros, maximus sit amet volutpat non,
                    facilisis vehicula eros. Morbi rhoncus eu quam ut pharetra. Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit. Suspendisse aliquet ligula in sem
                    sodales tristique. Cras varius massa ante, eu faucibus augue pellentesque
                    vitae. Integer sit amet neque dolor. Donec nec condimentum metus, vel laoreet
                    erat.

                  </p>
                </Card.Description>
                <Card.Description className="hero-child">
                  <iframe title="ICODA Workspace Video" width="100%" height="315" src={LINK_EMBED_PREVIEW_OVERVIEW_VIDEO} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>

        <Divider />

        <Header as="h2">Quick Start</Header>
        <Grid stackable columns="equal">
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <img src={FAIRLogo2020Green} alt="FAIR Logo" aria-hidden="true" className="right floated icon" />
                <a className="workspace-name" href={LINK_PREVIEW_FAIR} target="_blank" rel="noreferrer">1. Find Data</a>
              </Card.Header>
              <Card.Content>
                <Card.Description>
                  <ol>
                    <li>
                      Go to FAIR Data Services
                      <a href={LINK_PREVIEW_FAIR} className="ui button" target="_blank" rel="noreferrer">
                        <img alt="FAIR logo" src={FAIRLogo2020White} className="icon" style={{ display: 'inline-block' }} />
                        {' '}
                        Data Services
                      </a>
                    </li>
                    <li>
                      Review the existing datasets: Click 'Datasets' and select ‘All datasets’ in
                      the dropdown
                      <a title="Datasets" className="ui button aridhia-data-launcher" href={LINK_PREVIEW_FAIR_DATASETS} target="_blank" rel="noreferrer">
                        <i aria-hidden="true" className="database icon" />
                        Datasets
                      </a>
                    </li>
                    <li>Click on an individual dataset to review its metadata.</li>
                  </ol>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <img src={workspacesLogo2020Coloured} alt="Workspaces Logo" aria-hidden="true" className="right floated icon" />
                <a className="workspace-name" href={ENDPOINT_REQUEST_WORKSPACE}>2. Request a Workspace</a>
              </Card.Header>
              <Card.Content>
                <Card.Description>
                  <ol>
                    <li>
                      <strong>When you have found a dataset of interest</strong>
                      , request a project workspace
                      <a href={ENDPOINT_REQUEST_WORKSPACE} className="ui button">Request a workspace</a>
                    </li>
                    <li>Once you have a workspace, make a data access request in FAIR.</li>
                  </ol>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <img src={FAIRLogo2020Green} alt="FAIR Logo" aria-hidden="true" className="right floated icon" />
                <a className="workspace-name" target="_blank" rel="noreferrer" href={LINK_PREVIEW_FAIR}>3. Request Data</a>
              </Card.Header>
              <Card.Content>
                <Card.Description>
                  <p><strong>Now you are ready to request data.</strong></p>
                  <ol>
                    <li>Go back to FAIR</li>
                    <li>Navigate to the relevant dataset</li>
                    <li>
                      Request a dataset by clicking on the Request Icon
                      {' '}
                      <i aria-hidden="true" className="key icon" />
                      in the menubar.
                    </li>
                    <li>Fill in the form – you will receive feedback from the data owner.</li>
                  </ol>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>

        <Divider />

        <Header as="h2">Useful Information</Header>

        <Grid stackable columns="equal">
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <Icon name="shield alternate" size="large" className="right floated blue" />
                <a className="workspace-name" href={ENDPOINT_SECURITY}>Data security</a>
              </Card.Header>
              <Card.Content>
                <Card.Description>
                  <p>
                    Data can be highly sensitive: it can be at risk for re-identification even
                    when explicit personally identifiable information is removed. Find out more
                    about the measures that we use to keep data safe.
                  </p>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <Icon name="question circle outline" size="large" className="right floated blue" />
                <a className="workspace-name" target="_blank" rel="noreferrer" href={LINK_ARIDHIA_CONTACT_SD}>Need Help?</a>
              </Card.Header>
              <Card.Content>
                <Card.Description className="need-help">
                  <div>
                    <a href={LINK_ARIDHIA_CONTACT_SD} target="_blank" rel="noreferrer">
                      <i aria-hidden="true" className="life-ring x-large icon blue" />
                      Service Desk
                    </a>
                  </div>
                  <div>
                    <a href={LINK_ARIDHIA_KB} target="_blank" rel="noreferrer">
                      <i aria-hidden="true" className="question circle outline x-large icon blue" />
                      Knowledge Base
                    </a>
                  </div>
                  <div>
                    <a href={LINK_ARIDHIA_SHINY_APPS} target="_blank" rel="noreferrer">
                      <i aria-hidden="true" className="desktop x-large icon blue" />
                      Shiny Apps
                    </a>
                  </div>
                  <div>
                    <a href={LINK_ARIDHIA_LEARNING_CENTRE} target="_blank" rel="noreferrer">
                      <i aria-hidden="true" className="graduation x-large icon blue" />
                      Learning Centre
                    </a>
                  </div>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment className="portal-elements">
        <img src={poweredByAridhia} alt="Powered by Aridhia DRE" className="powered-by-aridhia" />
      </Segment>
    </Container>
  </div>

);

export default Content;
