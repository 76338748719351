import React from 'react';

import { Header, Button } from 'semantic-ui-react';

import DREOverview from '../images/DRE-Overview-Cropped.png';

function toggleOverview() {
  const element = document.getElementById('flex');
  if (element.classList.contains('overview-hidden')) {
    document.getElementById('overview-content').style.marginLeft = '1rem';
  } else {
    const contentWidth = document.getElementById('overview-content').offsetWidth - 56;
    document.getElementById('overview-content').style.marginLeft = `-${contentWidth}px`;
  }
  element.classList.toggle('overview-hidden');
}

const Overview = () => (
  <div className="overview">
    <Button type="button" aria-label="Toggle Overview" className="overview_toggle toggle left" onClick={toggleOverview}><i className="arrow circle left icon" /></Button>
    <Button type="button" aria-label="Toggle Overview" className="overview_toggle toggle right" onClick={toggleOverview}><i className="arrow circle right icon" /></Button>
    <div className="content" id="overview-content">
      <Header as="h2">Overview</Header>
      <p>
        <strong>
          This can be customised to your individual requirements and branded to your company's
          image.
        </strong>
      </p>

      <img src={DREOverview} alt="Workspaces Overview" className="ui image" />

      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vehicula tristique
        vestibulum. Vestibulum sed sapien leo. Donec condimentum commodo quam, nec
        ullamcorper libero euismod quis. Nullam non velit efficitur, commodo augue sit
        amet, aliquet nulla. Aenean lobortis enim felis, non placerat nisi porta et.
        Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis
        egestas. Donec accumsan eros diam, imperdiet imperdiet lacus cursus vitae. Cras nec
        consectetur dolor. Maecenas sagittis risus eu turpis luctus bibendum. Quisque sed
        orci libero. Morbi vitae tortor ultrices, venenatis mi et, placerat sem.
      </p>

      <p>
        Vivamus congue velit orci. Aenean laoreet ultricies mauris, sit amet consequat velit
        maximus eget. Pellentesque habitant morbi tristique senectus et netus et malesuada fames
        ac turpis egestas. Morbi at accumsan quam, et interdum felis. Maecenas sit amet rutrum mi,
        nec porta arcu. Proin non ligula justo. Pellentesque habitant morbi tristique senectus et
        netus et malesuada fames ac turpis egestas. Pellentesque habitant morbi tristique senectus
        et netus et malesuada fames ac turpis egestas. Mauris felis orci, mollis vel euismod
        vulputate, rhoncus in tellus. Duis interdum egestas augue et euismod.
      </p>

      <p>
        Etiam a nulla sapien. Ut ut nisi eros. Vestibulum at porttitor neque. Nam vulputate lectus
        nulla, a tempus tortor sodales et. Praesent consectetur ante eget dictum placerat.
        Pellentesque molestie et nisl sagittis euismod. Curabitur interdum mattis felis blandit
        fringilla. Fusce odio felis, interdum id odio non, laoreet fermentum nisi. Cras in sem sit
        amet massa eleifend hendrerit. In hac habitasse platea dictumst. Duis faucibus ultrices
        faucibus. Quisque lacus felis, molestie sed urna nec, viverra egestas nisi. Morbi iaculis
        lobortis magna.
      </p>

    </div>
  </div>
);

export default Overview;
